<template>
  <UsersList/>
</template>

<script>
export default {
  name: 'users',
  components: {
    UsersList: () => import('@/components/users/UsersList')
  }
}
</script>